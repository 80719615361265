<script setup lang="ts">
    const copyUrl = () => {
        navigator.clipboard.writeText(window.location.href);
        alert('URL copied to clipboard!');
    };
</script>

<template>
    <li><a class="dropdown-item" href="#" @click="copyUrl">Copy URL to clipboard</a></li>
    <li><a class="dropdown-item" href="#">Edit</a></li>
    <li><a class="dropdown-item" href="#">Download</a></li>
</template>